<template>
  <div class="g-section">
    <div class="g-wrap flex-column">
      <content-header :titleList="titleList" />
      <div class="from-content">
        <detail-form :isView="true" />
      </div>
    </div>
  </div>
</template>
<script>
import DetailForm from './components/detailform';
import ContentHeader from "@/components/Common/ContentHeader.vue";
export default {
  components: { DetailForm, ContentHeader },
  data() {
    return {
      titleList: [
        {
          text: "合作院校",
          isCur: false,
          link: ""
        },
        {
          text: "详情",
          isCur: true,
          link: ""
        }
      ]
    }
  }
};
</script>

<style lang="scss" scoped>
.g-section {
  background: #f5f5f5;
}
</style>